.ant-layout-header {
    height: var(--header-height);
    line-height: var(--header-height);
}

.ant-menu-dark {
    font-size: 16px;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
    background: transparent;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background: transparent;
    box-shadow: inset 0 -5px 0 0 var(--color-highlight);
}

.ant-menu-dark .ant-menu-item > a {
    color: #fff;
}

.ant-menu-dark .ant-menu-item > a:hover {
    color: var(--color-highlight);
}

.ant-btn-primary {
    color: rgba(255, 255, 255, .75);
    border: none;
    background: var(--gradient);
}

.ant-btn:hover, .ant-btn:focus {
    color: var(--color-default);
    border-color: var(--color-default);
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
    border: none;
    background: var(--gradient);
    color: #fff;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover, .ant-picker:hover, .ant-picker-focused, .ant-input:hover {
    border-color: var(--color-primary);
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused, .ant-picker-focused, .ant-input:focus, .ant-input-focused {
    border-color: var(--color-primary);
    box-shadow: 0 0 0 2px rgba(66, 224, 213, .2);
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
    background: var(--color-ultra-highlight);
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
    border-color: var(--color-highlight);
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: var(--color-primary);
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
    color: var(--color-text-colored);
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: var(--color-primary);
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: var(--color-primary);
    box-shadow: 0 0 0 2px rgba(66, 224, 213, .2);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: var(--color-ultra-highlight);
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: var(--color-default);
    border-color: var(--color-default);
}

.ant-radio-button-wrapper:hover {
    color: var(--color-default);
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: 0 0 0 2px rgba(66, 224, 213, .2);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: var(--color-default);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
    background-color: var(--color-default);
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background: var(--color-default);
    border-color: var(--color-default);
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: var(--color-default);
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: var(--color-ultra-highlight);
}

.ant-picker-range .ant-picker-active-bar {
    background: var(--color-primary);
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: var(--color-default);
}

.ant-btn-link {
    color: var(--color-default);
}

.ant-btn-link:hover, .ant-btn-link:focus {
    color: var(--color-primary);
    border-color: transparent;
    background: transparent;
}

.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
    color: #ff7875;
    border-color: #ff7875;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--color-text-colored);
}

.ant-tabs-tab:hover, .ant-tabs-tab:active, .ant-tabs-tab-btn:active {
    color: var(--color-text-colored);
}

.ant-tabs-ink-bar {
    background: var(--color-primary);
}

.ant-spin {
    color: var(--color-primary);
}

.ant-spin-dot-item {
    background-color: var(--color-primary);
}

.ant-pagination-item-active, .ant-pagination-item-active:hover, .ant-pagination-item:hover {
    border-color: var(--color-primary);
}

.ant-pagination-item-active a, .ant-pagination-item-active:hover a, .ant-pagination-item:hover a {
    color: var(--color-primary);
}

.ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
    color: var(--color-primary);
    border-color: var(--color-primary);
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: var(--color-primary);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner, .ant-checkbox-checked::after {
    border-color: var(--color-default);
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--color-default);
    border-color: var(--color-default);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: var(--color-dark);
}

.ant-radio-checked::after, .ant-radio-checked .ant-radio-inner, .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: var(--color-default);
}

.ant-radio-inner::after {
    background-color: var(--color-default);
}

.ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px rgba(66, 224, 213, .12);
}

#custom-user-form .ant-tabs-content {
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
}
